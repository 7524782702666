import {useMutation} from '@tanstack/react-query';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {apiClient} from '../../http/query-client';
import {showHttpErrorToast} from '../../utils/http/show-http-error-toast';
import {
  getBootstrapData
} from "@common/core/bootstrap-data/use-backend-bootstrap-data";

interface Response extends BackendResponse {}

interface Payload {
  entryIds?: number[];
  deleteForever?: boolean;
  paths?: string[];
}

function deleteFileEntries(payload: Payload): Promise<Response> {
  let url = getBootstrapData().settings.base_url;
  const storage = getBootstrapData().user?.storage;
  if (storage) {
    url = storage.upload_url;
  }

  if (getBootstrapData().api_web_token) {
    apiClient.defaults.headers.common['Authorization'] = 'Bearer '+getBootstrapData().api_web_token;
  }

  const tempCookie = document.cookie;
  document.cookie = `XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

  const data = apiClient
    .post((url ? url + '/api/v1/' : '') + 'file-entries/delete', payload)
    .then(r => r.data);

  document.cookie = tempCookie;
  return data;
}

export function useDeleteFileEntries() {
  return useMutation({
    mutationFn: (props: Payload) => deleteFileEntries(props),
    onError: err => showHttpErrorToast(err),
  });
}
